:root {
  // primary
  --color-blue: #2E4FD7;
  --color-royal-blue: #0A0A91;
  --color-purple: #5F65F3;
  --color-light-blue: #7E93F8;
  --color-coral: #CC2C3C;
  --color-light-coral: #EB5463;
  --color-green: #2BCDAB;
  --color-light-green: #49EBC9;
  --color-aqua: #007DAE;
  --color-light-aqua: #36B9EA;
  --color-dark: #495057;
  --color-dark-text: #2B3357;

  // gray
  --color-gray0-5: #F8F8F8;
  --color-gray1: #F3F4FF;
  --color-gray2: #D5D6E3;
  --color-gray3: #A3A4B1;
  --color-gray4: #535461;
}

