.rt-input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .rt-input__invalid .mat-mdc-text-field-wrapper {
    border-color: #f44336;
  }

  .inputs-date-picker__input .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;

    & label {
      margin-bottom: 0;

    }
  }

  .rt-input_remove-input-padding {
    .mat-mdc-form-field.mat-mdc-form-field-label-always-float {
      padding-top: 0;
    }
  }

  .rt-input_flex-1 {
    .mat-mdc-form-field {
      display: flex;
      flex: 1;
    }
  }

  .rt-input_remove-validation-margin {
    .rt-validation {
      margin-bottom: 0;
    }
  }
}

.rt-input__label {
  font-size: 12px;
  color: #4d4e5a;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.rt-input__label_invalid {
  color: #f44336;
}

