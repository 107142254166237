.blog {
  // font-family: 'IBM Plex Sans', sans-serif;
  margin-top: 50px;
  margin-bottom: 50px;

  .blog-intro p {
    font-size: 28px;
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    color: var(--color-gray4);
  }

  .full-width {
    width: inherit;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 800px) {
      max-width: 100%;
      height: auto;
    }

  }

  .richtext-image {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .left {
    float: left;
    margin: 0 16px 0 0;
  }

  .right {
    float: right;
    margin: 0 0 0 16px;
  }

  .richtext-image.left, .richtext-image.right {
    @media (max-width: 500px) {
      float: none;
      width: inherit;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
  }

  .paymentCard {
    margin: 10px !important;
    font-size: 70%;

    .paymentCard__priceCommon {
      font-size: 27px;
    }

    .paymentCard__year {
      font-size: 14px;
    }

    .paymentCard__title {
      font-size: 19px;

    }

    .paymentCard__details {
      padding: 0 10px;
      font-size: 14px;
    }

    .paymentCard__features {
      padding-left: 15px;
      font-size: 14px;
    }

    .action {
      text-align: center;
      .button {
        font-size: 13px;
        padding: 4px 3px;
        min-width: 120px;
        display: inline-block;
      }
    }
  }

  a {
    font-weight: 500;
    color: var(--color-blue);
  }

  .video-container {
    position:relative;
    padding-bottom:56.25%;
    height: 0;
    overflow: hidden;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.blog.container {
  max-width: 800px;
}

.blog-thumbnail {
  max-width: 100%;
  height: auto;

  @media (max-width: 959px) {
    width: 100%;
  }
}

.blog-date {
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #8B8B8B;
}

.blog-category {
  color: #9c9ea1;
  font-size: 18px;
}


.blog-title {
  margin-bottom: 10px;
  display: block;
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  color: #2F2E41;

  @media (min-width: 600px) {
    min-height: 75px; //temporary
  }
}

.blog-short-info {
  font-weight: 300;
  color: #000000;

  p {
    font-size: 16px;
    line-height: 28px;
  }
}

.news-item__content {
  p {
     font-size: 16px;
     line-height: 24px;
     font-weight: 300;
     color: #000000;

     &:last-of-type {
       margin-bottom: 0.1rem;
     }
   }
   a {
    color: var(--color-blue);
    font-weight: 600;
   }
 }

 .subscription-error {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 350;
  line-height: 1.57;
  letter-spacing: 0.42px;
  text-align: center;
  color: #f00;
}


// Blog root page styles.
.blog-root {
  .short-content p {
    font-size: 16px;
    color: #535461;
    margin-bottom: 0;
  }

  .news-item .content p {
    margin-bottom: 5px;
  }

  .mat-drawer-side {
    background-color: inherit;
    background: none;
    border: none;
  }

  .subscribe__newsletter {
    rt-loading {
      width: 100%;
    }
  }

  .newsletter__rt-input {
    margin: 0 0 35px;
    .mat-form-field {
      .mat-form-field-flex {
        background: #ffffff;
        border: 2px solid #b4b4b3;
        border-radius: 6px;
      }

      .mat-form-field-infix {
        padding: 2px 0;
        border: none;

        label {
          padding-left: 10px;
          line-height: 34px;
        }
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-label-wrapper {
        overflow: initial;
      }

      input {
        border-bottom: none;
      }
    }

    .mat-error {
      display: block;
      margin-top: 17px;
      color: #e53935;
      margin-left: 30px;
    }
  }
}
// Blog root page styles.
