$darkest-bg: #868686;

$white: #fff;
$red: #b94a48;


$brand-color-red: $red;
$brand-color-red-dark: $red;
$brand-color-white: $white;

$neutral-color-white: $white;
$neutral-color-lighter-grey: #f4f7fa;
$neutral-color-light-grey: #dce1e6;
$neutral-color-grey: #b4bec8;
$neutral-color-dark-grey: #63737d;
$neutral-color-black: #000;

$utility-color-info: #8c96a0;
$utility-color-positive: $darkest-bg;
$utility-color-neutral: #00afff;
$utility-color-caution: #ff9619;
$utility-color-negative: #ff3232;
$utility-color-action: #9c2aa0;

$utility-color-pale-positive: #7de19b;
$utility-color-pale-neutral: #64cdff;
$utility-color-pale-caution: #ffc87d;
$utility-color-pale-negative: #ff9b9b;
$utility-color-pale-action: #c47fc6;

$font-size-xs: 7pt;
$font-size-9: 9pt;
$font-size-sm: 10pt;
$font-size-11: 11pt;
$font-size-12: 12pt;
$font-size-md: 14pt;
$font-size-lg: 20pt;

$main-width: 1200px;
$main-safe-width: 1440px;
$customers-tree-width: 278px;
$main-content-padding: 25px;
$navbar-height: 56px;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder-color($color, $important: "") {
  &::-webkit-input-placeholder {
    color: $color #{$important};
  }
  &::-moz-placeholder {
    color: $color #{$important};
  }
  /* Firefox 19+ */
  &:-moz-placeholder {
    color: $color #{$important};
  }
  /* Firefox 18- */
  &:-ms-input-placeholder {
    color: $color #{$important};
  }
}

@mixin horizontal-icon-flip {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 3px;
}

// Vars from Zeplin
$lightish-blue: #3e66f7;
$turquoise-blue: #00b3ba;
$dark-indigo: #0d1c3d;
$dark-indigo-8: rgba(13, 28, 61, 0.08);
$violet-blue: #740fcc;
$tealish: #2fcfab;
$dark-grey-blue: #2b3357;
$battleship-grey: #6b7487;
$dark-grey-blue-50: rgba(43, 51, 87, 0.5);
$light-periwinkle: #e1e3e7;
$white: #ffffff;
$battleship-grey-50: rgba(107, 116, 135, 0.5);
$black-54: rgba(0, 0, 0, 0.54);

$testimonial-grey: #F8F8F8;
